import axios from 'axios';
import { getToken } from '../misc/Cookies';

/** @return {import("axios").AxiosInstance} */
const instanceAxios = axios.create({
  baseURL: process.env.VUE_APP_ALGO_DOMAIN,
  headers: {
    Authorization: getToken() || process.env.VUE_APP_TOKEN_URL,
    'Content-Type': 'application/json',
  },
  timeout: 1000 * (+process.env.VUE_APP_TIMEOUT_BE || 12), // default 12 seconds
  timeoutErrorMessage: 'please check your connection',
});

export default class CustomerAppAPI {
  constructor() {
    /** @type {import("axios").AxiosInstance} */
    this.$axios = instanceAxios;
  }

  /**
     *
     * @param err {object|null|unknown}
     * @return {string|unknown}
     */
  setErrorMessage(err) {
    if (err.data) {
      return err.data.message.id;
    }

    if (err.response && err.response.data.message && err.response.data.message.id) {
      return err.response.data.message.id;
    }

    if (err.code === 'ECONNABORTED') {
      return err;
    }

    return 'something went wrong';
  }
}
